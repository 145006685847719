import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { getGatsbyImageData } from "gatsby-source-sanity";
import clientConfig from "../../client-config";
import { cn } from "../lib/helpers";

import * as styles from "./figure.module.css";

const getColumns = (columns) => {
  switch (columns) {
    case "1":
      return "w-full md:w-1/12";
      break;
    case "2":
      return "w-full md:w-2/12";
      break;
    case "3":
      return "w-full md:w-3/12";
      break;
    case "4":
      return "w-full md:w-4/12";
      break;
    case "5":
      return "w-full md:w-5/12";
      break;
    case "6":
      return "w-full md:w-6/12";
      break;
    case "7":
      return "w-full md:w-7/12";
      break;
    case "8":
      return "w-full md:w-8/12";
      break;
    case "9":
      return "w-full md:w-9/12";
      break;
    case "10":
      return "w-full md:w-10/12";
      break;
    case "11":
      return "w-full md:w-11/12";
      break;
    case "12":
      return "w-full";
      break;
  }
};

export function Figure({ node, mode, loading, columns = "" }) {
  console.log("node", node);
  if (!node?.asset) {
    return null;
  }

  const imageData = getGatsbyImageData(node.asset, { maxWidth: 2000 }, clientConfig.sanity);

  console.log("imageData", imageData);
  if (mode !== "fill") {
    return (
      <figure className={cn(styles.root, columns && getColumns(columns))}>
        <GatsbyImage
          placeholder="dominantColor"
          loading={loading || "lazy"}
          style={{ objectFit: "contain", height: "100%" }}
          imgStyle={{ objectFit: "contain" }}
          objectFit="contain"
          objectPosition="top center"
          loading="eager"
          image={imageData}
          alt={node.alt}
        />
        {node.caption && <figcaption className="text-sm mt-2">{node.caption}</figcaption>}
      </figure>
    );
  } else {
    return (
      <figure className={styles.rootFill}>
        <GatsbyImage
          placeholder="dominantColor"
          loading={loading || "lazy"}
          image={imageData}
          alt={node.alt}
          style={{ objectFit: "cover", height: "100%" }}
          imgStyle={{ objectFit: "cover" }}
          objectFit="cover"
          objectPosition="center"
          loading="eager"
        />
      </figure>
    );
  }
}
